<template>
  <div>
    <a-tooltip v-show="showToolTip" placement="topLeft">
      <template slot="title">
        <div>
          <Content :content="content" :replaceTag="replaceTag" />
        </div>
      </template>
      <div ref="contentRef" :class="{ content: tip }">
        <Content :content="content" :replaceTag="replaceTag" />
      </div>
    </a-tooltip>
    <div v-show="!showToolTip" ref="contentRef" :class="{ content: tip }">
      <Content :content="content" :replaceTag="replaceTag" />
    </div>
  </div>
</template>

<script>
import Content from './itemContent.vue'
export default {
  name: 'item',
  components: {
    Content,
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    tip: {
      type: Boolean,
      default: true,
    },
    replaceTag: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showToolTip: false,
    }
  },
  mounted() {
    this.tip && this.isShowToolTip()
  },
  methods: {
    isShowToolTip() {
      const el = this.$refs.contentRef
      this.showToolTip = el.clientWidth < el.scrollWidth
    },
  },
}
</script>

<style lang="less" scoped>
.content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>

<template>
  <div>
    <template v-if="replaceTag">{{ content | tag }}</template>
    <template v-else>{{ content }}</template>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
    replaceTag: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<template>
  <NeoPageLayout>
    <div class="project-translationRecord">
      <header>
        <div class="title">翻译记录</div>
        <!--  表格查询 -->
        <section class="searchbox">
          <div class="table-operations">
            <a-form-model ref="form" :model="form">
              <a-row>
                <a-col :span="11" class="pd-0">
                  <a-form-model-item :label="$t('orderInfo.source')" class="mb-2">
                    <a-select
                      v-model="form.sourceCode"
                      placeholder="Source"
                      showSearch
                      allowClear
                      :filter-option="langFilterOption"
                      @change="onLangSourceChange"
                    >
                      <a-select-option
                        class="select-w"
                        v-for="(item, index) in sourceCodeList"
                        :key="index"
                        :value="item.code"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="11">
                  <a-form-model-item :label="$t('orderInfo.target')" class="mb-2">
                    <a-select
                      v-model="form.targetCode"
                      placeholder="Target"
                      showSearch
                      allowClear
                      :filter-option="langFilterOption"
                      @change="onLangTargetChange"
                    >
                      <a-select-option
                        class="select-w"
                        v-for="(item, index) in targetCodeList"
                        :key="index"
                        :value="item.code"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="2">
                  <div class="_flex _fr">
                    <a-button @click="resetSearch()" class="filter3-button ml-2">{{ $t('reset') }}</a-button>
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="11" class="pd-0">
                  <a-form-model-item class="mb-2" :label="$t('trans_record.source_text')" prop="orderCodeOrName">
                    <a-input v-model="form.sourceContent" placeholder="请输入原文" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="11">
                  <a-form-model-item class="mb-2" :label="$t('trans_record.trans_text')" prop="orderCodeOrName">
                    <a-input v-model="form.targetContent" placeholder="请输入译文" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="2">
                  <div class="_flex _fr">
                    <a-button type="primary" @click="searchTable()" class="filter3-button ml-2">{{
                      $t('search')
                    }}</a-button>
                  </div>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="11" class="pd-0">
                  <a-form-model-item class="mb-2" :label="$t('trans_record.source')" prop="dueDate">
                    <a-input v-model="form.orderAndCode" placeholder="请选择订单号/订单名" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="11">
                  <a-form-model-item class="mb-2" :label="$t('newOrder.product_line')">
                    <!-- <a-input v-model="form.productLineName" placeholder="请输入产品线名" /> -->
                    <a-select
                      mode="multiple"
                      v-model="form.productLineIds"
                      placeholder="请选择产品线"
                      showSearch
                      allowClear
                      :filter-option="langFilterOption"
                    >
                      <a-select-option
                        class="select-w"
                        v-for="(item, index) in cusProductLineList"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </a-form-model>
          </div>
        </section>
      </header>
      <main>
        <!-- 表格展示 -->
        <a-table
          class="striped no-br no-border"
          size="middle"
          rowKey="id"
          :data-source="dataInfo"
          :pagination="pagination"
          :loading="tableLoading"
          :scroll="{ x: $g.scrollXWidth }"
        >
          <a-table-column key="a" :ellipsis="true" title="语言对">
            <template slot-scope="row">
              <item :content="getLangPair(row)" />
            </template>
          </a-table-column>
          <a-table-column key="sourceContent" :ellipsis="false" title="原文">
            <template slot-scope="row">
              <item :content="row.sourceContent" :tip="false" :replaceTag="true" />
            </template>
          </a-table-column>
          <a-table-column key="targetContent" :ellipsis="false" title="译文">
            <template slot-scope="row">
              <item :content="row.targetContent" :tip="false" :replaceTag="true" />
            </template>
          </a-table-column>
          <a-table-column key="productLineName" :ellipsis="true" :title="$t('orderInfo.product_line')">
            <template slot-scope="row">
              <item :content="row.productLineName" />
            </template>
          </a-table-column>
          <a-table-column key="orderName" :ellipsis="true" title="来源">
            <template slot-scope="row">
              <div class="th-source _point" @click="onClick(row)">
                <item :content="`<${row.orderCode}>${row.orderName}`" />
              </div>
            </template>
          </a-table-column>
        </a-table>
      </main>
    </div>
  </NeoPageLayout>
</template>

<script>
import item from './item'

export default {
  name: 'translationRecord',
  components: {
    item,
  },
  data() {
    return {
      rowSpan: 8,
      labelCol: { span: 2 },
      wrapperCol: { span: 20 },
      //查询参数
      form: {
        sourceCode: undefined,
        targetCode: undefined,
        sourceContent: undefined,
        targetContent: undefined,
        orderAndCode: undefined,
        productLineName: undefined,
        productLineIds: [],
      },
      searchForm: {},
      dataInfo: [],
      pagination: {
        current: 1,
        size: 'middle',
        pageSize: 20,
        pageSizeOptions: ['20', '50'],
        showSizeChanger: true,
        total: 0,
        onShowSizeChange: (current, pageSize) => {
          this.pagination.current = 1
          this.pagination.pageSize = pageSize
          this.fetch()
        },
        onChange: (page) => {
          this.pagination.current = page
          this.fetch()
        },
      },
      tableLoading: false,
      // 产线列表
      cusProductLineList: [],
      // 语言对列表
      historyLanguagePair: [],
      // 源语言列表
      sourceCodeList: [],
      // 目标语言列表
      targetCodeList: [],
    }
  },
  computed: {
    langPair() {
      return this.$store.state.app.langList
    },
  },
  mounted() {
    this.fetch()
    this.getCusProductLineList()
    this.getTranslationHistoryLanguagePair()
  },
  methods: {
    onClick(row) {
      // 跳转到订单详情
      const { orderId: id } = row
      this.$router.push({ path: this.$g.route.order_orderInfo, query: { id } })
    },
    getLangPair(row) {
      const source = this.$store.getters['app/getLangNameByCode'](row.sourceCode) || '---'
      const target = this.$store.getters['app/getLangNameByCode'](row.targetCode) || '---'
      return `${source} > ${target}`
    },
    async fetch() {
      try {
        const p = this.pagination
        const page = { page: p.current, limit: p.pageSize }
        const form = this.searchForm
        const params = { ...page, ...form }
        const info = await this.$http('translationHistory', params)
        this.dataInfo = info.list
        this.pagination.total = info.total
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    searchTable() {
      this.pagination.current = 1
      this.searchForm = { ...this.form }
      this.searchForm.productLineIds = this.form.productLineIds?.length > 0 ? this.form.productLineIds.join(',') : ''
      this.fetch()
    },
    clearForm() {
      for (const key in this.form) {
        this.form[key] = undefined
      }
      this.searchForm = { ...this.form }
    },
    resetSearch() {
      this.pagination.current = 1
      this.clearForm()
      this.fetch()
    },
    onLangSourceChange(e) {
      if (e) {
        const targetCodeList = this.historyLanguagePair.find((item) => item.sourceCode == e).targetCodes
        this.targetCodeList = this.langPair.filter(function (item) {
          let bool = false
          targetCodeList.forEach(function (it) {
            if (it == item.code) {
              bool = true
            }
          })
          return bool
        })
      }
    },
    onLangTargetChange(e) {
      if (e) {
        let sourceCodeList = []
        this.historyLanguagePair.forEach((item) => {
          if (item.targetCodes.includes(e)) {
            sourceCodeList = [...sourceCodeList, item.sourceCode]
          }
        })
        this.sourceCodeList = this.langPair.filter(function (item) {
          let bool = false
          sourceCodeList.forEach(function (it) {
            if (it == item.code) {
              bool = true
            }
          })
          return bool
        })
      }
    },

    // 产线列表
    async getCusProductLineList() {
      try {
        const data = await this.$http('cusProductLineEntry')
        this.cusProductLineList = data
      } catch (error) {
        this.$httpNotify(error)
      }
    },

    // 语言对列表
    async getTranslationHistoryLanguagePair() {
      try {
        const data = await this.$http('translationHistoryLanguagePair')
        this.historyLanguagePair = data
        this.sourceCodeList = this.langPair.filter(function (item) {
          let bool = false
          data.forEach(function (it) {
            if (it.sourceCode == item.code) {
              bool = true
            }
          })
          return bool
        })
        let targetCodeList = []
        data.forEach((item) => {
          targetCodeList = [...targetCodeList, ...item.targetCodes]
        })
        const set = new Set(targetCodeList)
        const newTargetCodeList = Array.from(set)
        this.targetCodeList = this.langPair.filter(function (item) {
          let bool = false
          newTargetCodeList.forEach(function (it) {
            if (it == item.code) {
              bool = true
            }
          })
          return bool
        })
      } catch (error) {
        this.$httpNotify(error)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.project-translationRecord {
  padding: 20px;
  header {
    .title {
      font-size: 24px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #46546c;
      line-height: 36px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .searchbox {
      .table-operations {
        .filter3-button {
          width: 83px;
        }

        ::v-deep {
          .ant-form-item-label {
            line-height: 32px;
            margin-right: 10px;
            width: 64px;
          }
          .ant-form-item-control-wrapper {
            width: 90%;
          }
          .mb-2.ant-row.ant-form-item {
            display: flex;
            padding-left: 84px;
          }
          .ant-form-item-control {
            line-height: 32px;
          }
        }
        .pd-0 {
          ::v-deep {
            .mb-2.ant-row.ant-form-item {
              padding-left: 0 !important;
            }
          }
        }
      }
    }
    // .lang_pair {
    //   & > div:first-of-type,
    //   & > div:last-of-type {
    //     width: calc(100% - 29px * 0.5) !important;
    //   }
    // }
  }
  main {
    padding-top: 30px;
    .th-source {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #4080ff;
    }
  }
}
</style>
